import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';
import { SeoService } from 'src/app/services/seo.service';
import 'firebase/firestore';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  cities;

  constructor(
    private title: Title,
    private meta: Meta,
    private seo: SeoService,
    private db: AngularFirestore
  ) { }

  ngOnInit(): void {

    this.seo.generateTags({
      title: 'Steun lokale ondernemers | HalloBezorger',
      description: 'Bestel eten direct uit jouw stad!',
      keywords: 'den bosch, amsterdam restaurants, winkels, bezorgen, bezorging, thuisbezord'
    });

    this.cities = this.db.collection('locations', ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'id' });
  }

}
