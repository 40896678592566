// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
      apiKey: 'AIzaSyDD4B8Xnq5x0dAq7mgH-pYv00CLs-wv3Nc',
      authDomain: 'sandbox-hallobezorger.firebaseapp.com',
      databaseURL: 'https://sandbox-hallobezorger.firebaseio.com',
      projectId: 'sandbox-hallobezorger',
      storageBucket: 'sandbox-hallobezorger.appspot.com',
      messagingSenderId: '562738715548',
      appId: '1:562738715548:web:1cce5d538514f55bacc659',
      measurementId: 'G-VD4SKBTRC1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
