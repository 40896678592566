
<header>
   
</header>

<div class="innerview">
    <div *ngIf="location | async as doc">

        <h1>Afhalen of laten bezorgen in {{ doc.name }}</h1>
        <p>{{ doc.intro }}</p>
    
    </div>

    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <h2>Restaurants</h2>
    
    <mat-nav-list [disableRipple]="false" role="navigation">
    
        <mat-list-item *ngFor="let rest of restaurants | async">
            
            <img matListAvatar src="../../../assets/ietsanders.jpg" alt="...">
            <a mat-line [routerLink]="['/bezorging', rest.slug]"> {{ rest.name }}</a>
            <div matLine>
            {{rest.delivery_options}}
            </div>
        
            <!-- <button mat-icon-button>
                <mat-icon>info</mat-icon>
             </button> -->
    
        </mat-list-item> 
    
    </mat-nav-list>

    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <h2>Winkels</h2>
    
    <mat-nav-list [disableRipple]="false" role="navigation">
    
        <mat-list-item *ngFor="let rest of stores | async">
            
            <img matListAvatar src="../../../assets/ietsanders.jpg" alt="...">
            <a mat-line [routerLink]="['/bezorging', rest.slug]"> {{ rest.name }}</a>
            <div matLine>
            {{rest.delivery_options}}
            </div>
        
            <!-- <button mat-icon-button>
                <mat-icon>info</mat-icon>
             </button> -->
    
        </mat-list-item> 
    
    </mat-nav-list>
</div>
<div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>
<app-footer></app-footer>

