import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { SeoService } from 'src/app/services/seo.service';
import { ActivatedRoute } from '@angular/router';
import faFacebook from '@iconify/icons-logos/facebook';
import faTwitter from '@iconify/icons-logos/twitter';
import faInstagram from '@iconify/icons-logos/instagram-icon';

@Component({
  selector: 'app-winkels',
  templateUrl: './winkels.component.html',
  styleUrls: ['./winkels.component.scss']
})
export class WinkelsComponent implements OnInit {

  restaurants;
  cities;
  seoId: string;
  regularDistribution = 20 + '%';

  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;

  constructor(
    private title: Title,
    private meta: Meta,
    private seo: SeoService,
    private db: AngularFirestore,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    console.log(this.route.snapshot.paramMap.get('id'));
    this.seoId = this.route.snapshot.paramMap.get('id');

    this.seo.generateTags({
        title: 'Bestel eten direct uit jouw stad! | HalloBezorger',
        description: 'Bestel eten direct uit jouw stad!',
        keywords: 'den bosch, restaurants, winkels, bezorgen, bezorging, thuisbezord'
      });

    this.cities = this.db.collection('pages', ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'id' });
    this.restaurants = this.db.collection('merchants', ref => ref
    .where('isEnabled', '==', true)
    .where('category', '==', 'Winkel')
    .where('seo_id', '==', this.seoId))
    .valueChanges({ idField: 'id' });
    }

  }
