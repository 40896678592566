import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { VragenComponent } from './components/vragen/vragen.component';
import { RestaurantsComponent } from './components/restaurants/restaurants.component';
import { WinkelsComponent } from './components/winkels/winkels.component';
import { RestaurantDetailComponent } from './components/restaurants/restaurant-detail/restaurant-detail.component';
import { ContactComponent } from './components/contact/contact.component';
import { CitiesComponent } from './components/cities/cities.component';
import { CityComponent } from './components/cities/city/city.component';
import { AanmeldenComponent } from './components/aanmelden/aanmelden.component';
import { MerchantDetailComponent } from './components/merchants/merchant-detail/merchant-detail.component';
import { NotFoundComponent } from './not-found.component';


const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const deactivateGuard = new InjectionToken('deactivateGuard');

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'merchants', component: MerchantsComponent },
  { path: 'vragen', component: VragenComponent },
  { path: 'restaurants', component: RestaurantsComponent },
  { path: 'winkels', component: WinkelsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'aanmelden', component: AanmeldenComponent },
  { path: 'externalRedirect', canActivate: [externalUrlProvider], component: NotFoundComponent },
  { path: 'bezorging/:id', component: MerchantDetailComponent },
  { path: 'locaties', component: CitiesComponent },
  { path: 'locatie/:id', component: CityComponent },
  { path: 'locatie/:id/restaurants', component: RestaurantsComponent },
  { path: 'locatie/:id/winkels', component: WinkelsComponent },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
  providers: [
    {
        provide: externalUrlProvider,
        useValue: (route: ActivatedRouteSnapshot) => {
            const externalUrl = route.paramMap.get('externalUrl');
            window.open(externalUrl, '_blank');
        },
    },
  ]
})
export class AppRoutingModule { }
