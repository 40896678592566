<header>
    <app-svg-viewer src="https://www.hallobezorger.nl/assets/header.svg" [scaleToContainer]="true"
            style="display: block;"> </app-svg-viewer>

</header>

<div class="innerview">

  <main>
    <h1>Jouw binnenlocatie online!</h1>
    <p>
      Heb je iets nodig uit een winkel of gewoon zin in wat lekkers te eten? <br> Plaats dan je bestelling bij lokale
      ondernemers en ondersteun ze in deze moeilijke tijden. HalloBezorger bezorgd het dan bij je thuis.
      Dezelfde dag als je dat wil! Kijk hier voor een overzicht van deelnemers in 
      <a routerLink="locatie/den-bosch/restaurants">horeca</a> of <a routerLink="locatie/den-bosch/winkels">retail</a>.
    </p>

    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <div style="display: block;">
      <h2>Hoe werkt het?</h2>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="60px" style="display: block;">

        <div fxFlex="row">
          <app-svg-viewer src="https://www.hallobezorger.nl/assets/shop.svg" [scaleToContainer]="true"
            style="display: block;"> </app-svg-viewer>
          <h3>Stap 1</h3>
          <div style="display: block; min-height: 200px;">
            <p>Ga naar de overzichtspagina en kies een deelnemende ondernemer (horeca of retail).</p>
            <div><a routerLink="locatie/den-bosch/restaurants">Overzicht horeca</a> | <a routerLink="locatie/den-bosch/winkels">Overzicht winkels</a></div>
          </div>
        </div>

        <div fxFlex="row">
          <app-svg-viewer src="https://www.hallobezorger.nl/assets/select.svg" [scaleToContainer]="true"
            style="display: block;"></app-svg-viewer>
          <h3>Stap 2</h3>
          <p>Heeft deze ondernemer een online shop? Plaats dan daar je bestelling. Als een ondernemer geen webshop heeft, 
             geef dan je bestelling door zoals de
            ondernemer deze heeft aangegeven op zijn profielpagina.</p>
        </div>

        <div fxFlex="row">
          <app-svg-viewer src="https://www.hallobezorger.nl/assets/cicle.svg" [scaleToContainer]="true"
            style="display: block;"></app-svg-viewer>
          <h3>Stap 3</h3>
          <p>Je product wordt op het door jou gewenste moment geleverd door de winkelier of door HalloBezorger. </p>
        </div>

        <div fxFlex="row">

          <app-svg-viewer src="https://www.hallobezorger.nl/assets/payment.svg" [scaleToContainer]="true"
            style="display: block;"></app-svg-viewer>
          <h3>Stap 4</h3>
          <p>Je product afrekenen kan via de webshop van de betreffende ondernemer, tikkie of bij HalloBezorger. 
           </p>
         <!--   Heeft deze geen webshop, reken dan
           af met Hallobezorger bij bezorging of via een Tikkie met de ondernemer. -->
        </div>

      </div>

    </div>

    

    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <h2>Boodschappenservice</h2>
    <p>
      We zijn druk bezig met het op zetten van een boodschappen service, zoeken de samewerking hiervoor met meerdere partijen die deze service al aanbieden tijdens deze corona uitbraak.
    </p>

    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <div style="display: block; width: 60%;">
      <h2>Categorie</h2>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" style="display: block;">

        <div fxFlex="row">
          <app-svg-viewer routerLink="locatie/den-bosch/restaurants" src="https://www.hallobezorger.nl/assets/horeca.svg" [scaleToContainer]="true"
            style="display: block;"> </app-svg-viewer>
        </div>

        <div fxFlex="row">
          <app-svg-viewer routerLink="locatie/den-bosch/winkels" src="https://www.hallobezorger.nl/assets/retail.svg" [scaleToContainer]="true"
            style="display: block;"></app-svg-viewer>
        </div>

      </div>
    </div>

    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <h2>Onze bezorgdienst</h2>
    <p>Onze fietskoeriers halen de bestelde producten op bij de winkel en bezorger de 
      producten in de avond bij je thuis. Op dit moment kunnen we bij de aangesloten 
      winkels de producten einde van de middag ophalen en vanaf 18:00 uur gaan leveren 
      in de postcode gebieden 5211, 5212, 5213, 5215, 5216, 5222, 5223, 5224, 5231, 5232, 
      5233, 5234, 5246. <br>
      De service kost op dit moment tijdens de testfase €4,95 in de bovengenoemde postcode gebieden met levering na 18:00.
    </p>
    <p>
      De bezorgdienst van HalloBezorger is zoveel mogelijk per fiets, aangezien sommige producten niet op de fiets passen kunnen we er voor kiezen om het product met een bestelbus te bezorgen.
    </p>

    <app-footer></app-footer>

  </main>

</div>