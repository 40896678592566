<div>
<footer class="docs-footer">
    <p>© 2020 HalloBezorger is een geregistreerd handelsmerk van AttiqLab B.V. 
        geregistreerd bij de Kamer van Koophandel Brabant, Nederland, nummer 73700029
        <br> <a href="/">Privacy</a> | <a href="/">Gebruikersvoorwaarden</a>  |  
        <span class="spacer"></span> <span>Versie: 0.0.4</span></p>

        <div class="my-6" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <a class="cursor-pointer" href="https://www.facebook.com/hallobezorger">
              <ic-icon [icon]="faFacebook" height="20px" width="20px"></ic-icon>
            </a>
            <a class="cursor-pointer" href="https://twitter.com/hallobezorger">
              <ic-icon [icon]="faTwitter" height="20px" width="20px"></ic-icon>
            </a>
            <a class="cursor-pointer" href="https://www.instagram.com/hallobezorger">
              <ic-icon [icon]="faInstagram" height="20px" width="20px"></ic-icon>
            </a>
          </div>


</footer>
</div>
    
