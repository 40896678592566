<div class="innerview">
<h1>Aanmelden bij HalloBezorger </h1>
<h3>
    Heb je een winkel, restaurant of marktkraam? meld je nu aan bij HalloBezorger </h3>

<div class="contactform">
<form [formGroup]="partnerForm" (submit)="onSubmit()">
    <p>Soort onderneming</p>
    <mat-form-field appearance="fill">
        <mat-label>Categorie</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let status of statuses" [value]="status.value">
            {{status.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Bedrijfsnaam</mat-label>
        <input matInput placeholder="Bedrijfsnaam" formControlName="name" required>
        <mat-error *ngIf="partnerForm?.controls['name'].hasError('required')">
            Bedrijfsnaam is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>
    <p>Naam contact persoon/eigenaar</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Voornaam</mat-label>
        <input matInput placeholder="Voornaam" formControlName="first_name" required>
        <mat-error *ngIf="partnerForm?.controls['first_name'].hasError('required')">
            Voornaam is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Achternaam</mat-label>
        <input matInput placeholder="Contact person" formControlName="last_name" required>
        <mat-error *ngIf="partnerForm?.controls['last_name'].hasError('required')">
            Achternaam is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>
    <p>Adres gegevens</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Straat</mat-label>
        <input matInput placeholder="Straat" formControlName="street" required>
        <mat-error *ngIf="partnerForm?.controls['street'].hasError('required')">
            Straat is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Huisnummer</mat-label>
        <input matInput placeholder="Straat" formControlName="house_number" required>
        <mat-error *ngIf="partnerForm?.controls['house_number'].hasError('required')">
            Huisnummer is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="postalcode" appearance="fill">
        <mat-label>Postcode</mat-label>
        <input matInput placeholder="Postcode" formControlName="postal_code" required>
        <mat-error *ngIf="partnerForm?.controls['postal_code'].hasError('required')">
            Postcode is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>
    
    <mat-form-field class="city" appearance="fill">
        <mat-label>Stad</mat-label>
        <input matInput placeholder="Stad" formControlName="city" required>
        <mat-error *ngIf="partnerForm?.controls['city'].hasError('required')">
            Stad is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Land</mat-label>
        <input matInput placeholder="Land" formControlName="country" value="Nederland">
    </mat-form-field>

    <p>Op welk telefoonnummer kunnen wij je bereiken?</p>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Telefoonnummer</mat-label>
        <input matInput placeholder="Telefoonnummer" formControlName="phone_number" required>
        <mat-error *ngIf="partnerForm?.controls['phone_number'].hasError('required')">
            Telefoonnummer is <strong>verplicht</strong>
        </mat-error>
    </mat-form-field>
    <p>Op welk email adres kunnen wij je bereiken?</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" required email>
        <mat-error *ngIf="partnerForm?.controls['email'].hasError('required')">
            Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="partnerForm?.controls['email'].hasError('email')">
            Email is <strong>invalid</strong>
        </mat-error>
    </mat-form-field>
    <p>Korte beschrijving van jouw zaak</p>
    <mat-form-field hintLabel="Max 140 characters" appearance="fill">
        <mat-label>Korte beschrijving</mat-label>
        <input matInput #input maxlength="140" placeholder="Korte beschrijving" formControlName="description">
        <mat-hint align="end">{{input.value?.length || 0}}/140</mat-hint>
      </mat-form-field>
    <p>Beschrijving</p>
    <mat-form-field appearance="fill">
        <mat-label>Beschrijving</mat-label>
        <textarea matInput formControlName="description_long"></textarea>
      </mat-form-field>
    <p>Website</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Website</mat-label>
        <input matInput formControlName="url">
    </mat-form-field>
    <p>Facebook</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Facebook link</mat-label>
        <input matInput formControlName="facebook">
    </mat-form-field>
    <p>Instagram</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Instagram link</mat-label>
        <input matInput formControlName="instagram">
    </mat-form-field>

    <p>Twitter</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Instagram link</mat-label>
        <input matInput formControlName="twitter">
    </mat-form-field>

    <p>Link naar foto</p>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Afbeelding</mat-label>
        <input matInput placeholder="Link afbeelding" formControlName="image">
    </mat-form-field>
    <p>Betaaling via</p>

    <mat-form-field appearance="fill">
        <mat-label>Betaal opties</mat-label>
        <mat-select formControlName="payment_options">
          <mat-option *ngFor="let option of paymentOptions" [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>
    <p>Heb je een eigen bezorgservice?</p>

    <mat-form-field appearance="fill">
        <mat-label>Opties</mat-label>
        <mat-select formControlName="delivery_options">
          <mat-option *ngFor="let option of options" [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <div>
        <button type="submit" mat-button [disabled]="partnerForm.invalid">Aanmelden</button>
    </div>    
</form>
</div>

</div>

<app-footer></app-footer>