import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';


import { IconModule } from '@visurel/iconify-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxJsonLdModule } from '@ngx-lite/json-ld';


import { NotFoundComponent } from './not-found.component';
import { ExternalUrlDirective } from './directives/external-url.directive';

import { AngularFireModule } from '@angular/fire';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { HomeComponent } from './components/home/home.component';
import { RestaurantsComponent } from './components/restaurants/restaurants.component';
import { WinkelsComponent } from './components/winkels/winkels.component';
import { VragenComponent } from './components/vragen/vragen.component';
import { ContactComponent } from './components/contact/contact.component';
import { CoronaComponent } from './components/corona/corona.component';
import { RestaurantDetailComponent } from './components/restaurants/restaurant-detail/restaurant-detail.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ShellComponent } from './shared/shell/shell.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SvgViewerModule } from './shared/svg-viewer';
import { HttpClientModule } from '@angular/common/http';
import { CitiesComponent } from './components/cities/cities.component';
import { CityComponent } from './components/cities/city/city.component';
import { AanmeldenComponent } from './components/aanmelden/aanmelden.component';
import { WinkelComponent } from './components/aanmelden/winkel/winkel.component';
import { ErrorComponent } from './components/error/error.component';
import { MerchantDetailComponent } from './components/merchants/merchant-detail/merchant-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    MerchantsComponent,
    HomeComponent,
    RestaurantsComponent,
    WinkelsComponent,
    VragenComponent,
    ContactComponent,
    CoronaComponent,
    RestaurantDetailComponent,
    ShellComponent,
    FooterComponent,
    CitiesComponent,
    CityComponent,
    AanmeldenComponent,
    WinkelComponent,
    ErrorComponent,
    MerchantDetailComponent,
    NotFoundComponent,
    ExternalUrlDirective

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'HalloBezorger' }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSliderModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatChipsModule,
    MatListModule,
    IconModule,
    MatDividerModule,
    NgxJsonLdModule,
    FlexLayoutModule,
    SvgViewerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
