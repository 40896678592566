import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Bestel eten, booschappen en winkel direct uit jouw stad! | HalloBezorger');
    this.meta.updateTag({ name: 'description', content: 'Bestel eten, booschappen en winkel direct uit jouw stad!' });
    this.meta.updateTag({ name: 'keywords', content: 'den bosch, restaurants, winkels, bezorgen, bezorging, thuisbezord' });

  }

}
