import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import faFacebook from '@iconify/icons-logos/facebook';
import faTwitter from '@iconify/icons-logos/twitter';
import faInstagram from '@iconify/icons-logos/instagram-icon';
import faPinterest from '@iconify/icons-logos/pinterest';
import faGithub from '@iconify/icons-logos/github-icon';
import icMail from '@iconify/icons-ic/twotone-mail';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'HalloBezorger';

  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faPinterest = faPinterest;
  faGithub = faGithub;
  icMail = icMail;

  constructor(router: Router) {

  }
}
