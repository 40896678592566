import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Merchant } from 'src/app/models/merchant.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-aanmelden',
  templateUrl: './aanmelden.component.html',
  styleUrls: ['./aanmelden.component.scss']
})
export class AanmeldenComponent implements OnInit {

  partnerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    house_number: new FormControl('', Validators.required),
    postal_code: new FormControl('', Validators.required),
    country: new FormControl('Nederland', Validators.required),
    city: new FormControl('', Validators.required),
    phone_number: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    payment_options: new FormControl(''),
    delivery_options: new FormControl(''),
    description: new FormControl(''),
    description_long: new FormControl(''),
    url: new FormControl(''),
    image: new FormControl(''),
    facebook: new FormControl(''),
    instagram: new FormControl(''),
    twitter: new FormControl(''),
    category: new FormControl(''),
  });

  statuses: any[] = [
    { value: 'Winkel', name: 'Winkel' },
    { value: 'Restaurant', name: 'Restaurant' },
    { value: 'Wijnwinkel', name: 'Wijnwinkel' },
    { value: 'Slijterij', name: 'Slijterij' },
    { value: 'Bar', name: 'Bar' },
    { value: 'Bakkerij', name: 'Bakkerij' },
    { value: 'Slagerij', name: 'Slagerij' },
    { value: 'Groentenman', name: 'Groentenman' },
    { value: 'Markt', name: 'Marktkoopman' },
    { value: 'Overig', name: 'Overig' }
  ];

  cities: any[] = [
    { value: 'Den Bosch', name: 'Den Bosch' },
    { value: 'Amsterdam', name: 'Amsterdam' },
    { value: 'Utrecht', name: 'Utrecht' },
    { value: 'Eindhoven', name: 'Eindhoven' },
    { value: 'Tilburg', name: 'Tilburg' },
    { value: 'Den Haag', name: 'Den Haag' },
    { value: 'Rotterdam', name: 'Rotterdam' },
  ];

  options: any[] = [
    { value: 'Afhalen', name: 'Afhalen' },
    { value: 'Bezorgen', name: 'Bezorgen' },
    { value: 'Afhalen, Bezorgen', name: 'Afhalen en Bezorgen' },
    { value: 'Geen', name: 'Nog niet' },
  ];

  paymentOptions: any[] = [
    { value: 'Pinnen', name: 'Pinnen' },
    { value: 'iDeal', name: 'iDeal' },
    { value: 'Tikkie', name: 'Tikkie' },
    { value: 'Betaalverzoek', name: 'Betaalverzoek' },
    { value: 'Contact', name: 'Contant' },
  ];

  constructor(
    private db: AngularFirestore,
    private seo: SeoService,
    ) { }

  ngOnInit(): void {
    this.seo.generateTags({
      title: 'Aanmelden bij HalloBezorger #supportyourlocals',
      description: 'Heb je een winkel, restaurant of marktkraam en je wilt lokaal verkopen ,meld je nu aan bij HalloBezorger',
      keywords: 'lokale restaurants, lokale markt, lokale winkels, bezorgen, bezorging, thuisbezord'
    });
  }

  onSubmit(): void {

      this.createPartner(this.partnerForm.value).then(
        () => {
          // this.notifier.show({
          //   type: 'success',
          //   message: 'Partner is created successfully!',
          // });

          // this.location.back();
        });
  }

  get diagnostic() { return JSON.stringify(this.partnerForm.value); }

  findInvalidControls(f: FormGroup) {
    const invalid = [];
    const controls = f.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  createPartner(partner: Merchant): Promise<any> {
    return this.db.collection('merchants').add(partner);
  }

}
