import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'firebase/firestore';
import { SeoService } from 'src/app/services/seo.service';
import { Observable } from 'rxjs';
import { Merchant } from 'src/app/models/merchant.model';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-restaurant-detail',
  templateUrl: './restaurant-detail.component.html',
  styleUrls: ['./restaurant-detail.component.scss']
})
export class RestaurantDetailComponent implements OnInit {

  slug: string;
  merchant: Observable<Merchant>;

  private itemDoc: AngularFirestoreDocument<Merchant>;
  item: Observable<Merchant>;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private seo: SeoService
  ) { }

  ngOnInit() {

    this.slug = this.route.snapshot.paramMap.get('id');

    this.merchant = this.db
      .collection<Merchant>('merchants', ref => ref
        .where('slug', '==', this.slug))
      .valueChanges().pipe(
        tap(docs => {
          console.log('docs', docs);
          this.seo.generateTags({
            title: docs[0].name,
            description: docs[0].description,
          });
        }),
        map(val => val.length > 0 ? val[0] : null)
      );
  }

}
