import { Component, OnInit } from '@angular/core';
import {materialVersion} from '../version';
import faFacebook from '@iconify/icons-logos/facebook';
import faTwitter from '@iconify/icons-logos/twitter';
import faInstagram from '@iconify/icons-logos/instagram-icon';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version;

  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;


  constructor() { }

  ngOnInit(): void {

    this.version = materialVersion;

  }

}
