<div style="height: 200px; display: block; overflow: hidden; background-color: #D8F7FF;">
    <app-svg-viewer src="https://www.hallobezorger.nl/assets/header.svg" [scaleToContainer]="true"> </app-svg-viewer>
</div>

<div class="innerview" fxLayout="column">

    <div *ngIf="merchant | async as doc">

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" fxFlex>



            <div fxFlex="1 6 80%">
                <h1>{{ doc.name }}</h1>
                <span>{{doc.street}} {{doc.house_number}} {{doc.postal_code}} {{doc.city}}</span>
                <h3>Omschrijving</h3>
                <p>{{doc.description}}</p>
            </div>

            <div fxFlex="1 6 20%">

                <div class="my-6" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                    <a mat-raised-button color="primary" [href]="doc.url">Bestel hier</a>
                    </div>
                    <div class="hspace"></div>

    <li *ngIf="doc.payment_options">{{doc.payment_options}}</li>
    <li *ngIf="doc.delivery_options">{{doc.delivery_options}}</li>

    <div class="hspace"></div>

                
                <div class="my-6" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                    <a *ngIf="doc.facebook" class="cursor-pointer" [href]="doc.facebook">
                      <ic-icon [icon]="faFacebook" height="20px" width="20px"></ic-icon>
                    </a>
                    <a *ngIf="doc.twitter" class="cursor-pointer" [href]="doc.twitter">
                      <ic-icon [icon]="faTwitter" height="20px" width="20px"></ic-icon>
                    </a>
                    <a *ngIf="doc.instagram" class="cursor-pointer" [href]="doc.instagram">
                      <ic-icon [icon]="faInstagram" height="20px" width="20px"></ic-icon>
                    </a>
                  </div>
            </div>

        </div>

        <div class="hspace"></div>
        <mat-divider></mat-divider>
        <div class="hspace"></div>

    </div>



    <app-footer></app-footer>