import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';
import { SeoService } from 'src/app/services/seo.service';
import 'firebase/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.scss']
})
export class RestaurantsComponent implements OnInit {

  restaurants;
  cities;
  seoId: string;

  constructor(
    private title: Title,
    private meta: Meta,
    private seo: SeoService,
    private db: AngularFirestore,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {


  console.log(this.route.snapshot.paramMap.get('id'));
  this.seoId = this.route.snapshot.paramMap.get('id');

  this.seo.generateTags({
      title: 'Bestel eten direct uit jouw stad! | HalloBezorger',
      description: 'Bestel eten direct uit jouw stad!',
      keywords: 'den bosch, restaurants, winkels, bezorgen, bezorging, thuisbezord'
    });

  this.cities = this.db.collection('pages', ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'id' });
  this.restaurants = this.db.collection('merchants', ref => ref
  .where('isEnabled', '==', true)
  .where('category', '==', 'Restaurant')
  .where('seo_id', '==', this.seoId))
  .valueChanges({ idField: 'id' });
  }

}
