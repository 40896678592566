<div class="example-container">
    <mat-form-field appearance="fill">
      <mat-label>Input</mat-label>
      <input matInput>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>Select</mat-label>
      <mat-select>
        <mat-option value="option">Option</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>Textarea</mat-label>
      <textarea matInput></textarea>
    </mat-form-field>
  </div>
  