<div class="hero-image">
    <div class="hero-text">
      <h1>Restaurants in Den Bosch</h1>
    </div>
  </div>

<div class="innerview">

    <h2>Welke restaurants in Den Bosch zijn nog open voor afhaal of bezorgen</h2>
    <p>Zodra we nieuwe aanmeldingen krijgen vullen we deze website uiteraard direct aan!</p>
<mat-nav-list [disableRipple]="false" role="navigation">

    <mat-list-item *ngFor="let rest of restaurants | async">
        
        <img matListAvatar src="{{rest.image}}" alt="{{rest.name}}}">
        <a mat-line [routerLink]="['/bezorging', rest.slug]"> {{ rest.name }}</a>
        <div matLine>
        
        <mat-chip-list *ngFor="let deliveries of rest.delivery">
            <mat-chip>{{deliveries}}</mat-chip>
        </mat-chip-list>   
        </div>
    
        <!-- <button mat-icon-button>
            <mat-icon>info</mat-icon>
         </button> -->

    </mat-list-item>

</mat-nav-list>





    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>
    <h2>Ik wil mijn restaurant aanmelden</h2>
    <p>
      Dat kan, je kan je gratis aanmelden via het aanmeld <a href="aanmelden">formulier</a>
    </p>


    <app-footer></app-footer>

</div>

    





