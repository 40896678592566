<h1>Rechtstreeks uit de binnenstad</h1>
<h3>Elke dag nieuwe aanbieders erbij</h3>


<mat-nav-list [disableRipple]="false" role="navigation">
    <!-- <h3 mat-subheader>Kies je stad</h3> -->
    <mat-list-item *ngFor="let city of cities | async">
        <a mat-line [routerLink]="['/locatie', city.id]"> {{ city.name }}</a>
    </mat-list-item>
</mat-nav-list>
<mat-divider></mat-divider>
<div>
