import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { ActivatedRoute } from '@angular/router';
import { CityLocation } from 'src/app/models/merchant.model';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {

  restaurants;
  stores;
  seoId;
  location: Observable<CityLocation>;

  constructor(
    private title: Title,
    private meta: Meta,
    private seo: SeoService,
    private db: AngularFirestore,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.seoId = this.route.snapshot.paramMap.get('id');

    this.location = this.db
      .collection<CityLocation>('locations', ref => ref
        .where('seo_id', '==', this.seoId))
      .valueChanges().pipe(
        tap(docs => {
          // console.log('docs', docs);
          this.seo.generateTags({
            title: docs[0].title,
            description: docs[0].description,
            keywords: docs[0].keywords,
          });
        }),
        map(val => val.length > 0 ? val[0] : null)
      );

    this.restaurants = this.db.collection('merchants', ref => ref
      .where('isEnabled', '==', true)
      .where('seo_id', '==', this.seoId)
      .where('category', '==', 'Restaurant')
    ).valueChanges({ idField: 'id' });


    this.stores = this.db.collection('merchants', ref => ref
      .where('isEnabled', '==', true)
      .where('seo_id', '==', this.seoId)
      .where('category', '==', 'Winkel')
    ).valueChanges({ idField: 'id' });

  }

}
