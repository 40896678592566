import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-winkel',
  templateUrl: './winkel.component.html',
  styleUrls: ['./winkel.component.scss']
})
export class WinkelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
