<mat-toolbar color="primary">
    <mat-toolbar-row>
          <a mat-button routerLink="/" aria-label="HalloBezorger">
            <img class="icon"
                 src="../assets/logo_color.svg"
                 alt="HalloBezorger">
            <span>HalloBezorger</span>
          </a>

    <button class="docs-navbar-hide-small docs-button" mat-button [routerLink]="['locatie/den-bosch/restaurants']" routerLinkActive="active">Restaurants</button>
    <button class="docs-navbar-hide-small docs-button" mat-button [routerLink]="['locatie/den-bosch/winkels']" routerLinkActive="active">Winkels</button>
    <!-- <button class="docs-navbar-hide-small docs-button" mat-button [routerLink]="['den-bosch/markt']" routerLinkActive="active">Markt</button> -->
    <!-- <button class="docs-navbar-hide-small docs-button" mat-button [routerLink]="['/aanmelden']" routerLinkActive="active">Aanmelden</button> -->

    <!-- <span class="spacer"></span>
    <button mat-button>Login</button> -->
    </mat-toolbar-row>
    
    <mat-toolbar-row class="docs-navbar docs-navbar-show-small">
        <nav class="docs-navbar docs-navbar-show-small" aria-label="Section Nav Bar">
            <button class="docs-navbar-link" mat-button [routerLink]="['locatie/den-bosch/restaurants']" routerLinkActive="active">Restaurants</button>
            <button class="docs-navbar-link" mat-button [routerLink]="['locatie/den-bosch/winkels']" routerLinkActive="active">Winkels</button>
            <!-- <button class="docs-navbar-link" mat-button [routerLink]="['/aanmelden']" routerLinkActive="active">Aanmelden</button> -->
        </nav>
    </mat-toolbar-row>
</mat-toolbar>


<!-- TRANSCLUSION -->
<ng-content></ng-content>


