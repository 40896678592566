
    <div class="hero-image">
        <div class="hero-text">
          <h1>Winkel in Den Bosch</h1>
        </div>
      </div>


<div class="innerview">

    <h2>Welke winkels in Den Bosch zijn nog open voor afhaal of bezorgen</h2>
    <p>Zodra we nieuwe aanmeldingen krijgen vullen we deze website uiteraard direct aan!</p>

    <mat-nav-list [disableRipple]="false" role="navigation">

        <mat-list-item *ngFor="let rest of restaurants | async">
            
            <img matListAvatar src="{{rest.image}}" alt="{{rest.name}}}">
            <a mat-line [routerLink]="['/bezorging', rest.slug]"> {{ rest.name }}</a>
            <div matLine>
            
            <mat-chip-list *ngFor="let deliveries of rest.delivery">
                <mat-chip>{{deliveries}}</mat-chip>
            </mat-chip-list>   
            </div>
        
            <!-- <button mat-icon-button>
                <mat-icon>info</mat-icon>
             </button> -->
    
        </mat-list-item>
    
    </mat-nav-list>

    <!-- <div fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutGap="30px" fxLayoutAlign="space-around none">

        <div fxFlex="25%" fxFlex.xs="100%" *ngFor="let rest of restaurants | async" style="padding-bottom: 40px;">

            <mat-card>
                
                <img matCardImage src="{{rest.image}}">
                <mat-card-header>
                    <mat-card-title>{{ rest.name }}</mat-card-title>
                    <mat-card-subtitle>{{rest.delivery_options}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>
                    {{rest.description}}
                    </p>
                    <div class="my-6" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
                        <a class="cursor-pointer" [href]="rest.facebook">
                          <ic-icon [icon]="faFacebook" height="20px" width="20px"></ic-icon>
                        </a>
                        <a class="cursor-pointer" [href]="rest.twitter">
                          <ic-icon [icon]="faTwitter" height="20px" width="20px"></ic-icon>
                        </a>
                        <a class="cursor-pointer" [href]="rest.instagram">
                          <ic-icon [icon]="faInstagram" height="20px" width="20px"></ic-icon>
                        </a>
                      </div>
                </mat-card-content>
                <mat-card-actions align="start">
                    
                    <a [href]="rest.url"appExternalUrl mat-button color="primary">Bestel hier</a>
                     <button mat-button [routerLink]="rest.url">Action</button> -->
                    <!-- <button mat-button [routerLink]="['/bezorging', rest.slug]">Bekijk winkel</button> -->
<!-- 
                </mat-card-actions>
                <mat-card-footer>
                    
                </mat-card-footer>
            </mat-card> -->

        <!-- </div> -->

    <!-- </div> --> 
    



     

<!-- <mat-nav-list [disableRipple]="false" role="navigation">

    <mat-list-item *ngFor="let rest of restaurants | async">
        
        <img matListAvatar src="../../../assets/ietsanders.jpg" alt="...">
        <a mat-line [routerLink]="['/bezorging', rest.slug]"> {{ rest.name }}</a>
        <div matLine>
        
        <mat-chip-list *ngFor="let deliveries of rest.delivery">
            <mat-chip>{{deliveries}}</mat-chip>
        </mat-chip-list>   
        </div>
    
        <button mat-icon-button>
            <mat-icon>info</mat-icon>
         </button>

    </mat-list-item>

</mat-nav-list> -->


    <div class="hspace"></div>
    <mat-divider></mat-divider>
    <div class="hspace"></div>

    <h2>Ik wil mijn winkel aanmelden</h2>
    <p>
      Dat kan, je kan je gratis aanmelden via het aanmeld <a href="aanmelden">formulier</a>
    </p>


    <app-footer></app-footer>

</div>