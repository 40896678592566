import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import faFacebook from '@iconify/icons-logos/facebook';
import faTwitter from '@iconify/icons-logos/twitter';
import faInstagram from '@iconify/icons-logos/instagram-icon';
import faPinterest from '@iconify/icons-logos/pinterest';
import faGithub from '@iconify/icons-logos/github-icon';
import icMail from '@iconify/icons-ic/twotone-mail';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    'name': 'HalloBezorger',
    'url': 'https://www.hallobezorger.nl'
  };

  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faPinterest = faPinterest;
  faGithub = faGithub;
  icMail = icMail;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Bestel eten, booschappen en winkel direct uit jouw stad! | HalloBezorger');
    this.meta.updateTag({ name: 'description', content: 'Bestel eten, booschappen en winkel direct uit jouw stad!' });
    this.meta.updateTag({ name: 'keywords', content: 'den bosch, restaurants, winkels, bezorgen, bezorging, thuisbezord' });

  }

}
